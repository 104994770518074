// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-dedicated-proxy-tsx": () => import("./../src/pages/products/dedicated-proxy.tsx" /* webpackChunkName: "component---src-pages-products-dedicated-proxy-tsx" */),
  "component---src-pages-products-http-proxy-tsx": () => import("./../src/pages/products/http-proxy.tsx" /* webpackChunkName: "component---src-pages-products-http-proxy-tsx" */),
  "component---src-pages-products-shared-proxy-tsx": () => import("./../src/pages/products/shared-proxy.tsx" /* webpackChunkName: "component---src-pages-products-shared-proxy-tsx" */),
  "component---src-pages-products-socks-proxy-tsx": () => import("./../src/pages/products/socks-proxy.tsx" /* webpackChunkName: "component---src-pages-products-socks-proxy-tsx" */),
  "component---src-pages-refunds-tsx": () => import("./../src/pages/refunds.tsx" /* webpackChunkName: "component---src-pages-refunds-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

